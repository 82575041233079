import $ from "jquery";
import 'daterangepicker'
import moment from 'moment'

document.addEventListener('turbolinks:load', function () {
    if ($('#created_at_range').length)  {
        console.log('$(\'#q_created_at_gteq\').val()', $('#q_created_at_gteq').val(), $('#q_created_at_lteq').val())
        const startDate = $('#q_created_at_gteq').val() !== "" ? moment($('#q_created_at_gteq').val()).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")
        const endDate = $('#q_created_at_lteq').val() !== "" ? moment($('#q_created_at_lteq').val()).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY")

        console.log('')
        console.log('startDate', startDate, endDate)
        $('#created_at_range').daterangepicker({
            startDate: startDate,
            endDate: endDate
        }, (start, end) => {
            $('#q_created_at_gteq').val(start.format("YYYY-MM-DD"))
            $('#q_created_at_lteq').val(end.format("YYYY-MM-DD"))
        })
    }
})