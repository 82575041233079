import accounting from 'plugins/accounting'
import $ from 'jquery'

const updateForm = function() {
  let ht = $('#invoice_price_before_tax').val()
  let vatRate = $("#invoice_vat_rate").val()
  let discount = $("#invoice_discount").val()

  if (ht == undefined) {
    return false
  }

  let it = (parseFloat(ht * (1 + (vatRate / 100)))).toString()
  it = (parseFloat(it - (it * discount / 100))).toString()
  let total = accounting.parseFloat(it)
  $('.invoice_products_form .lineitem').each(function(i, e) {
    let inputs = $(e).find(':input')
    let quantity = accounting.parseFloat($(inputs[1]).val())
    if (quantity < 0) {
      $(inputs[1]).val(0)
      quantity = 0
    }
    let price = accounting.parseFloat($(inputs[2]).val())
    if (price < 0) {
      $(inputs[2]).val(0)
      price = 0
    }

    let vat = accounting.parseFloat($(inputs[3]).val())
    if (vat < 0) {
      $(inputs[3]).val(0)
      vat = 0
    }

    let lineitemTotal = $(e).find('.lineitem-total')
    let lineitemTotalValue = quantity * (price * (1 + (vat / 100)))

    total += lineitemTotalValue

    $(lineitemTotal).text(accounting.formatMoney(lineitemTotalValue))
  })

  /*console.log($('.invoice_products_form'));
  console.log($('.invoice-total'));
  console.log($('.invoice_products_form .invoice-total'));*/

  $('.invoice_products_form .invoice-total').text(accounting.formatMoney(total))
}

$(document).on('change', '.invoice_products_form .product-name', function(e) {
  let productName = $(e.currentTarget).val()
  let price = $(`#products .product[data-name='${productName}']`).data('price')
  let quantityElm = $($(e.currentTarget).parents('.lineitem').find(':input')[1])
  quantityElm.val(1)
  //TODO: vérifier d'un point de vue UX s'il est pertinent de remettre le prix à 0 quand change le nom ou le produit
  let priceElm = $($(e.currentTarget).parents('.lineitem').find(':input')[2])
  priceElm.val(price)
  priceElm.attr('placeholder', price)
  updateForm()
})

$(document).on('click', '.remove-product', function(e) {
  $($(e.currentTarget).parents('.lineitem').find(':input')[1]).val(0)
  $($(e.currentTarget).parents('.lineitem').find(':input')[2]).val(0)
  updateForm()
})

$(document).on('change paste keyup', '#invoice_price_before_tax', function() {
  updateForm()
})

$(document).on('change paste keyup', '#invoice_vat_rate', function() {
  updateForm()
})

$(document).on('change paste keyup', '#invoice_discount', function() {
  updateForm()
})

$(document).on('change paste keyup', '.invoice_products_form', function() {
  updateForm()
})

/*
//Cette syntaxe ne fonctionne plus post installation de fullcalendar je pense
$(document).on('ready turbolinks:load', function () {
  updateForm()
})*/

document.addEventListener('turbolinks:load', function () {
  updateForm()
})

$(document).on("change", "#invoice_franchise_id", function(){
  let options = $(this)[0].options
  let selectedOption = options.item(options["selectedIndex"])
  let selectedValue = selectedOption.getAttribute("data-vat")
  $("#invoice_vat_rate").val(selectedValue).change()
})

$(document).on("change", "#invoice_vat_rate", function(){
  jQuery('#modalVatChange').modal('show')
})