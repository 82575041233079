import $ from 'jquery'

// function displayVat() {
//     if($("#franchise_has_vat").is(":checked")){
//         $(".franchise_vat_rate").show()
//     } else {
//         $("#franchise_vat_rate").val("0.0").change()
//         $(".franchise_vat_rate").hide()
//     }
// }

document.addEventListener('turbolinks:load', function () {
    // displayVat()
    $(".training_form button[type='submit']").on("click", function(e){
        e.preventDefault()
        $(this).unbind("click")
        if(!$("#franchise_has_vat").is(":checked")){
           $("#franchise_vat_rate").val("0.0").change()
        }
        $(this).click()
    })
})

// $(document).on("change", "#franchise_has_vat", function() {
//     displayVat()
// })