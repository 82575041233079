import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.min.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'
const axios = require('axios');

const select2_multiple_conf = {
  placeholder: 'Sélectionner un ou plusieurs éléments',
  allowClear: true,
  theme: "bootstrap",
  language: {
    noResults: function () {
      return "Aucun résultat trouvé"
    },
    errorLoading: function () {
      return "Les résultats ne peuvent pas être chargés."
    },
    searching: function () {
      return "Recherche en cours…"
    },
    removeAllItems: function () {
      return "Supprimer tous les éléments"
    }
  }
}
const select2_one_conf = {
  placeholder: 'Sélectionner un élément',
  allowClear: true,
  theme: "bootstrap",
  width: '100%',
  language: {
    noResults: function () {
      return "Aucun résultat trouvé"
    },
    errorLoading: function () {
      return "Les résultats ne peuvent pas être chargés."
    },
    searching: function () {
      return "Recherche en cours…"
    },
    removeAllItems: function () {
      return "Supprimer tous les éléments"
    }
  }
}
const select2_one_vat_exemption_conf = {
  placeholder: 'Sélectionner une raison d\'exonération de TVA',
  allowClear: true,
  theme: "bootstrap",
  language: {
    noResults: function () {
      return "Aucun résultat trouvé"
    },
    errorLoading: function () {
      return "Les résultats ne peuvent pas être chargés."
    },
    searching: function () {
      return "Recherche en cours…"
    },
    removeAllItems: function () {
      return "Supprimer tous les éléments"
    }
  }
}
const select2_one_tags_conf = {
  placeholder: 'Selectionner un élément ou saisisser son nom ',
  allowClear: true,
  theme: "bootstrap",
  tags: true,
  language: {
    noResults: function () {
      return "Aucun résultat trouvé"
    },
    errorLoading: function () {
      return "Les résultats ne peuvent pas être chargés."
    },
    searching: function () {
      return "Recherche en cours…"
    },
    removeAllItems: function () {
      return "Supprimer tous les éléments"
    }
  }
}
const config = { attributes: true, childList: true, subtree: true };

//variable pour le formulaire de devis
let addedItemline = false
if($("#add_quotation_products_button")){
  $(document).on("click", "#add_quotation_products_button", function() {
    addedItemline = true
  })
}
let changedVatRate = false
if($("#quotation_vat_rate")){
  $(document).on("change", "#quotation_vat_rate", function(){
    changedVatRate = true
  })
}

const callback = function(mutationsList, observer) {
  for(const mutation of mutationsList) {
    if (mutation && mutation.addedNodes) {
      mutation.addedNodes.forEach(function(elm) {
        $(elm).find('.select2_multiple').select2(select2_multiple_conf)
        $(elm).find('.select2_one').select2(select2_one_conf)
        $(elm).find('.select2_one.product-name').select2(select2_one_tags_conf) //TODO: voir si un meilleur nom de classe ne conviendrait pas

        /** JS pour le formulaire des devis (quotations) pour mettre la TVA des quotation_products en adéquation avec la TVA par défaut de la franchise sélectionné
         * Le comportement désiré est :
         * un changement de la franchise qui change la TVA va modifier la TVA par défaut de tous les produits complémentaires + les TVA déjà existantes
         * un changement du champ TVA seul va modifier la TVA par défaut de tous les produits complémentaires + les TVA déjà existantes
         * */

        if($(".row.lineitem") && (addedItemline || changedVatRate)){
          let selectedVatValue = $("#quotation_vat_rate")[0].value
          $(".row.lineitem").each(function() {
            observer.disconnect()
            let quotationProductSelect = $(":nth-child(4)", this)[1]
            $(quotationProductSelect).find("select").val(selectedVatValue).change()
          })
          addedItemline = false
          changedVatRate = false
          observer.observe(document.body, config);
        }
      })
    }
  }
}
var observer = new MutationObserver(callback);

$(document).on('ready turbolinks:load', function () {
  $('.select2_multiple').select2(select2_multiple_conf);
  $('.select2_one').select2(select2_one_conf);
  $('.select2_one_vat_exemption').select2(select2_one_vat_exemption_conf);
  $('.select2_one.product-name').select2(select2_one_tags_conf)
  observer.disconnect();
  observer = new MutationObserver(callback);
  observer.observe(document.body, config);
});

// Define events handlers of Select2 inputs here because
// change Events won't be catched by an other instance of '$' (jQuery)
$(document).on('ready turbolinks:load', function () {
  let requestPrice = function(url, customerId, trainingId) {
    try {
      const response = axios.get(url, { params: { customer_id: customerId, training_id: trainingId } })
      return response
    } catch (error) {
      console.error(error)
    }
  }

  let suggestPrice = async function(e, price_input) {
    let data = $(price_input)[0].dataset
    if (data == undefined) { return; }
    let customerId = data.customerId
    let trainingId = data.trainingId
    let url = data.suggestPriceUrl
    let label = $($(price_input)[0].labels[0])

    if (label.children('small').length == 0) {
      $('<small>').appendTo(label)
    }

    if (customerId && trainingId) {
      let res = await requestPrice(url, customerId, trainingId)
      let prices = res.data
      label.children('small').text(` (de base ${prices.base.formatted} / de la zone client ${prices.price.formatted})`)
      $(price_input).val(prices.price.value)
    } else {
      label.children('small').text('')
    }
  }

  let trainingChanged = function(e, price_input) {
    let trainingId = e.currentTarget.value
    $(price_input).attr('data-training-id', trainingId)
    suggestPrice(e, price_input)
  }

  let customerChanged = function(e, price_input) {
    let customerId = e.currentTarget.value
    $(price_input).attr('data-customer-id', customerId)
    suggestPrice(e, price_input)
  }

  $('#quotation_training_id').on("change", function(e) {
    trainingChanged(e, '#quotation_price')
  })
  $('#quotation_customer_id').on("change", function(e) {
    customerChanged(e, '#quotation_price')
  })
  $('#convention_training_id').on("change", function(e) {
    trainingChanged(e, '#convention_price')
  })
  $('#convention_customer_id').on("change", function(e) {
    customerChanged(e, '#convention_price')
  })



  // For training_session form -> input trainer

  let trainingSessionHalfDayPrice = $('#training_session_half_day_price_info')
  let trainingSessionHalfDayPriceInput = $('#training_session_half_day_price')[0]
  let trainingSessionDayPrice = $('#training_session_day_price_info')
  let trainingSessionDayPriceInput = $('#training_session_day_price')[0]
  let trainingSessionTrainerInput = $("#training_session_trainer_id")
  function getTrainerInfos(id){
    const url = `/account/trainers/${id}/trainer_infos`
    $.get({
      url :url,
      success: function(response) {
        trainingSessionDayPriceInput.value = ''
        trainingSessionHalfDayPriceInput.value = ''
        trainingSessionHalfDayPrice.html(response.half_day_price)
        trainingSessionDayPrice.html( response.day_price)
      },
      error: function(error) {
        //handle error
      }
    })
  }

  if(trainingSessionHalfDayPriceInput !== undefined && trainingSessionHalfDayPriceInput.value === "" && trainingSessionTrainerInput[0].value !== "") {
    getTrainerInfos(trainingSessionTrainerInput[0].value)
  }

  trainingSessionTrainerInput.on("change", function(e) {
    const trainerId = $(this)[0].value;
    getTrainerInfos(trainerId)
  });

  // For the Franchisor Form (Establishment)

  let franchisorVatRateInput = $("#franchisor_vat_rate");
  let franchisorVatExemptionDiv = $("#franchisor_vat_exemption_div");

  function displayVatExemption(value) {
    if (value === "0.0") {
      franchisorVatExemptionDiv.removeClass("d-none");
    } else {
      franchisorVatExemptionDiv.addClass("d-none");
    }
  }

  franchisorVatRateInput.on("change", function(e) {
    displayVatExemption($(this)[0].value)
  });

  if(franchisorVatRateInput[0])
  {
    displayVatExemption(franchisorVatRateInput[0].value);
  }
});
