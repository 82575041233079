import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import { French } from "flatpickr/dist/l10n/fr"


$(document).on('ready turbolinks:load', function () {
    flatpickr(".dates", {
        minDate: "today",
        dateFormat: "d/m/Y",
        "locale": French
    });
});

$(document).on('ready turbolinks:load', function () {
    flatpickr(".birth_date", {
        dateFormat: "d/m/Y",
        "locale": French
    });
});

$(document).on('ready turbolinks:load', function () {
    flatpickr(".morning_time", {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        maxTime: "12:30"
    });
});

$(document).on('ready turbolinks:load', function () {
    flatpickr(".afternoon_time", {
        enableTime: true,
        noCalendar: true,
        time_24hr: true,
        minTime: "12:30"
    });
});

if($(".add_date.add_fields")){
  $(document).on("click", ".add_date.add_fields", function() {
    flatpickr(".dates", {
        minDate: "today",
        dateFormat: "d/m/Y",
        "locale": French
    });
  })
}