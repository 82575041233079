import accounting from 'plugins/accounting'
import $ from 'jquery'

const updateForm = function() {
  let ht = $('#quotation_price_before_tax').val() * $('#quotation_quantity').val()
  let vatRate = $("#quotation_vat_rate").val()
  let discount = $("#quotation_discount").val()

  if (ht == undefined) {
    return false
  }

  let it = (parseFloat(ht * (1 + (vatRate / 100)))).toString()
  it = (parseFloat(it - (it * discount / 100))).toString()
  let total = accounting.parseFloat(it)
  $('.quotation_products_form .lineitem').each(function(i, e) {
    let inputs = $(e).find(':input')

    let quantity = accounting.parseFloat($(inputs[1]).val())
    if (quantity < 0) {
      $(inputs[1]).val(0)
      quantity = 0
    }

    let price = accounting.parseFloat($(inputs[2]).val())
    if (price < 0) {
      $(inputs[2]).val(0)
      price = 0
    }

    let vat = accounting.parseFloat($(inputs[3]).val())
    if (vat < 0) {
      $(inputs[3]).val(0)
      vat = 0
    }

    let lineitemTotal = $(e).find('.lineitem-total')
    let lineitemTotalValue = quantity * (price * (1 + (vat / 100)))

    total += lineitemTotalValue

    $(lineitemTotal).text(accounting.formatMoney(lineitemTotalValue))
  })

  $('.quotation_products_form .quotation-total').text(accounting.formatMoney(total))
}

$(document).on('change', '.quotation_products_form .product-name', function(e) {
  let productName = $(e.currentTarget).val()
  let price = $(`#products .product[data-name='${productName}']`).data('price')
  let quantityElm = $($(e.currentTarget).parents('.lineitem').find(':input')[1])
  quantityElm.val(1)
  let priceElm = $($(e.currentTarget).parents('.lineitem').find(':input')[2])
  priceElm.val(price)
  priceElm.attr('placeholder', price)
  updateForm()
})

$(document).on('click', '.remove-product', function(e) {
  $($(e.currentTarget).parents('.lineitem').find(':input')[1]).val(0)
  $($(e.currentTarget).parents('.lineitem').find(':input')[2]).val(0)
  updateForm()
})

$(document).on('change paste keyup', '#quotation_price_before_tax', function() {
  updateForm()
})

$(document).on('change paste keyup', '#quotation_quantity', function() {
  updateForm()
})

$(document).on('change paste keyup', '#quotation_vat_rate', function() {
  updateForm()
})

$(document).on('change paste keyup', '#quotation_discount', function() {
  updateForm()
})

$(document).on('change paste keyup', '.quotation_products_form', function() {
  updateForm()
})


document.addEventListener('turbolinks:load', function () {
  updateForm()
  if($("#quotation_without_training").is(":checked")){
    $(".quotation_training").hide()
    $(".quotation_price_before_tax").hide()
    $(".quotation_vat_rate").hide()
    $(".quotation_discount").hide()
  }

  //handle form submit for with / without training
  $(".quotation_form button[type='submit']").on("click", function(e){
    e.preventDefault()
    $(this).unbind("click")
    if($("#quotation_without_training").is(":checked")){
      $("#quotation_vat_rate option").prop("selected", false)
      $("#quotation_training_id option").prop("selected", false).change()
    }
    $(this).click()
  })
})

$(document).on("change", "#quotation_franchise_id", function(){
  let options = $(this)[0].options
  let selectedOption = options.item(options["selectedIndex"])
  let selectedValue = selectedOption.getAttribute("data-vat")
  $("#quotation_vat_rate").val(selectedValue).change()
})

$(document).on("change", "#quotation_vat_rate", function(){
  jQuery('#modalVatChange').modal('show')
})

//handle with / without training
$(document).on("change", "#quotation_without_training", function(){
  console.log($(".quotation_training"));
  if($(this).is(":checked")){
    $(".quotation_training").hide()
    $(".quotation_vat_rate").hide()
    $("#quotation_price_before_tax").val('')
    $(".quotation_price_before_tax").hide()
    $("#quotation_discount").val('')
    $(".quotation_discount").hide()
  } else {
    $(".quotation_training").show()
    $(".quotation_price_before_tax").show()
    $(".quotation_vat_rate").show()
    $(".quotation_discount").show()
  }
  updateForm()
})