import $ from "jquery";

document.addEventListener('turbolinks:load', function () {
  const autenticityToken = $('[name="csrf-token"]')[0].content

  //formulaire customer
  $(document).on("change", "#customer_user_id", function(){
    let userId = $(this).val()
    let url = "/account/admin/users/" + userId + "/get_infos"
    $.ajax({
      type: "PUT",
      data: $.param({
        authenticity_token: autenticityToken,
        id: userId
      }),
      url: url,
      success: function(response){
        $("#customer_contact_attributes_last_name").val(response.last_name)
        $("#customer_contact_attributes_first_name").val(response.first_name)
        $("#customer_contact_attributes_phone_one").val(response.phone)
        $("#customer_contact_attributes_mobile_phone").val(response.mobile_phone)
        $("#customer_contact_attributes_email").val(response.email)
      },
      error: function(error){
        //handle error
      }
    })
  })
})