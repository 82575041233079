$(document).on('ready turbolinks:load', function () {

    jQuery.fn.dataTable.render.ellipsis = function (cutoff, wordbreak, escapeHtml) {
        let esc = function (t) {
            return t
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;');
        };

        return function (d, type, row) {
            // Order, search and type get the original data
            if (type !== 'display' || d.substring(0, 2) === '<a') {
                return d;
            }
            if (typeof d !== 'number' && typeof d !== 'string') {
                return d;
            }
            d = d.toString(); // cast numbers
            if (d.length <= cutoff) {
                return d;
            }
            let shortened = d.substr(0, cutoff - 1);
            // Find the last white space character in the string
            if (wordbreak) {
                shortened = shortened.replace(/\s([^\s]*)$/, '');
            }
            // Protect against uncontrolled HTML input
            if (escapeHtml) {
                shortened = esc(shortened);
            }
            return '<span class="ellipsis">' + shortened + '&#8230;</span>';
        };
    };

    function createNewEvent(eventName) {
        let event;
        if (typeof (Event) === 'function') {
            event = new Event(eventName);
        } else {
            // IE compatibility
            event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
        }
        return event;
    }

    let table = $('#performance_formanature_table').DataTable({
        scrollX: true,
        scrollCollapse: true,
        paging: false,
        fixedColumns: {
            leftColumns: 2
        },
        oLanguage: {
            sSearch: "Filter in table"
        },

        columnDefs: [
            {
                targets: [0, 2],
                render: $.fn.dataTable.render.ellipsis(10)
            },
            {
                targets: [1],
                render: $.fn.dataTable.render.ellipsis(24)
            },
            {
                targets: '_all',
                orderable: false
            }
        ]
    });

    function setupTableSorting () {
        let currentOrderColName = $('#order_companies_by_colname_param').data('order_companies_by_colname')
        let currentOrderDirection = $('#order_companies_by_direction_param').data('order_companies_by_direction')

        if(currentOrderColName) {
            let currentSortedColHeader = $('th[data-colname=' + currentOrderColName + ']')
            let colIdxToSort = currentSortedColHeader.data('colidx')
            table.order([colIdxToSort, currentOrderDirection]).draw()
            currentSortedColHeader.addClass('sorting_' + currentOrderDirection)
        }

        function makeSortHandler(direction) {
            return function(e) {
                let colName = $(e.target).data('colname');
                let newUri = (new URI(window.location.href)).setQuery({
                    'order_companies_by_colname': direction === null ? '' : colName,
                    'order_companies_by_direction': direction
                }).build();
                window.location.href = newUri;
            }
        }

        $('th.sorting').click(makeSortHandler('desc'));
        $('th.sorting_desc').click(makeSortHandler('asc'));
        $('th.sorting_asc').click(makeSortHandler(null));
    }
    setupTableSorting();

    $('.left-part:not(.keep-show-when-toggle)').hide()

    setTimeout(function () {
        $('#performance_formanature_table').DataTable().draw()
    }, 500)

});