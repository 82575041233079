import $ from 'jquery'

const autenticityToken = $('[name="csrf-token"]')[0].content

document.addEventListener('turbolinks:load', function () {
    $('.update-version').each(function(){
        $(this).on("click", function(e){
            e.preventDefault()
            let id = $(this).parent().parent().parent()[0].id.substring(8)
            let type = $(this).parent().parent().parent().data("type")
            let idCustomer = $(this).parent().parent().parent().data("customer")
            let commentaryValue = $(this).parent().parent().parent().find("textarea").val()
            $.ajax({
                type: "PUT",
                data: $.param({
                    authenticity_token: autenticityToken,
                    commentary: commentaryValue,
                    type: type,
                    id_customer: idCustomer,
                }),
                url : "/account/admin/versions/" + id,
                success: function(result){
                    //handle result
                },
                error: function(error){
                    //handle error
                }
            })
        })
    })
})
