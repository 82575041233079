// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('@rails/ujs').start();
require('bootstrap');
require('admin-lte/dist/js/adminlte');
require('@nathanvda/cocoon')

import 'plugins/select2'
import 'plugins/flatpickr'
import 'plugins/dashboard_table'
import 'plugins/fullcalendar'
import 'plugins/jquery.doubleScroll'
// import 'plugins/admintle'
import 'views/franchises'
import 'views/quotations'
import 'views/invoices'
import 'views/customer-history'
import 'views/customer-form'
import 'views/franchise-form'
import 'views/trainer-form'
import 'views/dashboard'

document.addEventListener('turbolinks:load', function () {
    // hide/show location adress in training_session _form
    if(document.getElementById('training_session_customer_location_box')){
        document.getElementById('training_session_customer_location_box').onclick = function () {
            let form = document.getElementById("show_form");
            if (this.checked) {
                form.style.display = "none";
            } else {
                form.style.display = "block";
            }
        };
    }
    $(".sidebar-menu").tree({
        accordion: false
    })
    // $('[data-toggle="tooltip"]').tooltip();
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
