import $ from 'jquery'
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css';

$(document).on('ready turbolinks:load', function () {
  let calendarEl = document.getElementById('calendar');
  if (!calendarEl) return
  var calendar = new Calendar(calendarEl, {
    plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin ],
    locale: frLocale,
    dayMaxEvents: true,
    nowIndicator: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    navLinks: true,
    events: '/account/dashboard/calendar.json',
    eventDidMount: (info) => {
      const { address, customer_name, session_number, trainer_name, training_session_name } = info.event.extendedProps
      tippy(info.el, {
        content: `<h4>${training_session_name}</h4><h5>${trainer_name}</h5><p>${session_number}</p><p>${customer_name}<br />${address}</p>`,
        allowHTML: true,
        animation: 'scale',
      })
    }
  });

  calendar.render();
});

